import { defineMessages } from 'react-intl';

export default defineMessages({
  related_news: {
    id: 'league_river.related_news',
    defaultMessage: 'RELATED NEWS',
  },
  load_more: {
    id: 'league_river.load_more',
    defaultMessage: 'SEE MORE STORIES',
  },
  loading: {
    id: 'league_river.loading',
    defaultMessage: 'LOADING',
  },
});
