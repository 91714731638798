exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ScoreTicker__divider--XI0Eh {\n  float: left;\n  height: 100%;\n  color: #fff;\n  font-weight: 500;\n  font-size: 14px;\n  width: 40px;\n  padding-top: 35px;\n  margin-right: 10px;\n  text-align: center;\n}\n@media (max-width: 768px) {\n  .ScoreTicker__divider--XI0Eh {\n    padding-top: 17px;\n  }\n}\n\n.ScoreTicker__card--22qjE {\n  white-space: nowrap;\n  display: inline-block;\n}\n\n.ScoreTicker__link--donfC {\n  display: inline-block;\n  border-radius: 4px;\n}\n\n.ScoreTicker__link--donfC:focus {\n  outline: none;\n  border-color: #fff;\n}", ""]);

// exports
exports.locals = {
	"divider": "ScoreTicker__divider--XI0Eh",
	"card": "ScoreTicker__card--22qjE",
	"link": "ScoreTicker__link--donfC"
}; 
var style = module.exports.toString();module.exports = exports.locals || { stylesheet: "" };if (!module.exports.stylesheet) { module.exports.stylesheet = style; };