exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".NCAABTickerCTA__background--Jxskq {\n  background: no-repeat url(\"/static/images/ticker-bg.png\");\n  background-size: cover;\n  width: 125px;\n  height: 53px;\n  color: #f9f9f9;\n}\n\n.NCAABTickerCTA__bracketContainer--K7EUD {\n  width: 100px;\n  height: 46px;\n  margin: auto;\n  border-radius: 2px;\n  background-color: rgba(255, 154, 37, 0.875);\n  position: relative;\n  top: calc(50% - 23px);\n}\n\n.NCAABTickerCTA__bracket--3hKDh {\n  background: no-repeat url(\"/static/vectors/bracket.svg\");\n  height: 20px;\n  width: 20px;\n  display: inline-block;\n  position: relative;\n  top: calc(50% - 10px);\n  left: 2px;\n}\n\n.NCAABTickerCTA__text--2s3ZJ {\n  display: inline-block;\n  position: relative;\n  top: calc(50% - 15px);\n  left: 5px;\n  font-size: 11px;\n  font-style: italic;\n  line-height: 1.33;\n  font-weight: 500;\n}\n\n@media (min-width: 768px) {\n  .NCAABTickerCTA__container--2a9ZP {\n    display: none;\n  }\n}", ""]);

// exports
exports.locals = {
	"background": "NCAABTickerCTA__background--Jxskq",
	"bracketContainer": "NCAABTickerCTA__bracketContainer--K7EUD",
	"bracket": "NCAABTickerCTA__bracket--3hKDh",
	"text": "NCAABTickerCTA__text--2s3ZJ",
	"container": "NCAABTickerCTA__container--2a9ZP"
}; 
var style = module.exports.toString();module.exports = exports.locals || { stylesheet: "" };if (!module.exports.stylesheet) { module.exports.stylesheet = style; };