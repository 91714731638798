import { Ticker, GameScoreCard } from 'sports-react';
import PropTypes from 'prop-types';
import React from 'react';
import Link from '~/components/Link/Link';
import uniq from 'lodash/uniq';
import NCAABTickerCTA from '~/components/NCAABTickerCTA/NCAABTickerCTA';
import StyledComponent from '~/components/Styled/Styled';
import logEvent from '~/util/analytics';
import css from './ScoreTicker.scss';

const scoreCardClick = data => {
  logEvent('click', { type: 'score_card', ...data });
};

const ScoreTicker = props => {
  const scoreTickerTrack = (type, data) => {
    if (props.trackEvent) {
      props.trackEvent('score_ticker', { type, ...data });
    }
  };

  let previousGame;
  const games = props.games.slice();

  if (props.displayCTA) {
    games.unshift(<NCAABTickerCTA />);
  }

  const hasMultipleSports = uniq(props.games.map(game => game.league)).length > 1;

  const gamesMap = games.map(game => {
    if (game.type && props.displayCTA) {
      return (
        <div key="card" className={css.card}>
          {game}
        </div>
      );
    }
    const hasDivider =
      hasMultipleSports && (!previousGame || (previousGame && game.league !== previousGame.league));
    previousGame = game;

    const leagueName = game.league === 'worldcup' ? 'WCUP' : game.league.toUpperCase();

    return (
      <div key="card" className={css.card} style={{ marginRight: hasDivider ? '50px' : '0' }}>
        {hasDivider && (
          <div className={css.divider} tabIndex="-1">
            {leagueName}
          </div>
        )}
        <Link
          route={game.boxscoreUrl}
          key={game.boxscoreUrl}
          onClick={() => {
            scoreCardClick({ destination: game.boxscoreUrl });
          }}
        >
          <a className={css.link}>
            <GameScoreCard {...game} boxscoreUrl={null} prefetch />
          </a>
        </Link>
      </div>
    );
  });

  return (
    <Ticker
      trackEvent={scoreTickerTrack}
      height={props.height}
      ref={el => {
        if (el) {
          // eslint-disable-next-line no-param-reassign
          el.tickerScroll.scrollLeft = 0;
        }
      }}
    >
      {[...gamesMap]}
    </Ticker>
  );
};

ScoreTicker.displayName = 'ScoreTicker';

ScoreTicker.propTypes = {
  games: PropTypes.arrayOf(PropTypes.shape(GameScoreCard.propTypes)).isRequired,
  trackEvent: PropTypes.func, // eslint-disable-line react/no-unused-prop-types
  height: PropTypes.string,
  displayCTA: PropTypes.bool,
  leagueSlug: PropTypes.string,
};

ScoreTicker.defaultProps = {
  trackEvent: null,
  height: '100%',
  displayCTA: false,
  leagueSlug: 'nfl',
};

export default StyledComponent(ScoreTicker, [css]);
