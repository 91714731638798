import truncate from 'lodash/truncate';
import get from 'lodash/get';

const getCardTitle = card =>
  get(card, 'data.title') ||
  card.caption ||
  truncate(get(card, 'data.text'), {
    length: 90,
    separator: ' ',
  });

export default getCardTitle;
