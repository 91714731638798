import React from 'react';

import Link from '~/components/Link/Link';
import StyledComponent from '~/components/Styled/Styled';

import css from '~/components/NCAABTickerCTA/NCAABTickerCTA.scss';

// eslint-disable-next-line react/prefer-stateless-function
class NCAABTickerCTA extends React.PureComponent {
  render() {
    return (
      <div className={css.container}>
        <Link route="/ncaab/tournament">
          <a>
            <div className={css.background}>
              <div className={css.bracketContainer}>
                <div className={css.bracket} />
                <div className={css.text}>TOURNAMENT</div>
              </div>
            </div>
          </a>
        </Link>
      </div>
    );
  }
}

NCAABTickerCTA.displayName = 'NCAABTickerCTA';

export default StyledComponent(NCAABTickerCTA, [css]);
