import React from 'react';
import PropTypes from 'prop-types';
import StyledComponent from '~/components/Styled/Styled';
import Link from '~/components/Link/Link';
import ContentCardTypes from '~/constants/ContentCardTypes';
import logEvent from '~/util/analytics';

import css from '~/components/Headlines/Headlines.scss';

const Headlines = props => {
  const { headlines } = props;

  const handleClick = contentId => {
    logEvent('click', { type: 'headline', content_card_id: contentId });
  };

  return (
    <ul className={css.headlines} role="list">
      {headlines.map(headline => (
        <li className={css.headline} key={headline.contentId} role="listitem">
          <Link
            route={headline.url}
            onClick={() => {
              handleClick(headline.contentId);
            }}
            prefetch={headline.type !== ContentCardTypes.SOURCED_ARTICLE}
          >
            <a>
              <span className={css.headlineText}>{headline.title}</span>
            </a>
          </Link>
        </li>
      ))}
    </ul>
  );
};

Headlines.propTypes = {
  headlines: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      contentId: PropTypes.number,
      url: PropTypes.string.isRequired,
      type: PropTypes.string,
    })
  ).isRequired,
};

Headlines.displayName = 'Headlines';

export default StyledComponent(Headlines, [css]);
