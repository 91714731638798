import get from 'lodash/get';
import kebabCase from 'lodash/kebabCase';
import ContentCardTypes from '~/constants/ContentCardTypes';

const getWebUri = card => {
  if (card.type === ContentCardTypes.SOURCED_ARTICLE) return card.data.url;

  const slug = get(card, 'data.web_league_slug', null);
  const webUri = get(card, 'data.uri', null);
  const title = kebabCase(get(card, 'data.title', '').toLowerCase());
  const id = card.id;

  if (card.type === ContentCardTypes.THE_SCORE_ARTICLE) {
    const link = slug ? `/${slug}${webUri}/${title}` : `${webUri}/${title}`;
    return link;
  }

  return `/s/${id}`;
};

export default getWebUri;
