import React from 'react';
import PropTypes from 'prop-types';
import Headlines from '~/components/Headlines/Headlines';
import StyledComponent from '~/components/Styled/Styled';

import css from './HeadlinesCard.scss';

const HeadlinesCard = ({ headlines }) => (
  <div className={css.headlinesContainer}>
    <div className={css.headlinesHeader}>
      <h2 className={css.headlinesHeaderText}>HEADLINES</h2>
    </div>
    <div className={css.headlinesBody}>
      <Headlines headlines={headlines} />
    </div>
  </div>
);

HeadlinesCard.propTypes = {
  headlines: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      contentId: PropTypes.number,
      url: PropTypes.string,
    })
  ).isRequired,
};

HeadlinesCard.displayName = 'HeadlinesCard';

export default StyledComponent(HeadlinesCard, [css]);
