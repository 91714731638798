import React from 'react';
import PropTypes from 'prop-types';
import StyledComponent from '~/components/Styled/Styled';
import { injectIntl, intlShape } from 'react-intl';

import logEvent from '~/util/analytics';

import messages from '~/constants/localizations/league_river';
import css from '~/components/LoadMoreButton/LoadMoreButton.scss';

const LoadMoreButton = props => {
  const { intl: { formatMessage } } = props;

  if (props.isLoading) {
    return (
      <div className={css.loadMoreButton}>
        <div className={css.loadMoreText}>{formatMessage(messages.loading)}...</div>
      </div>
    );
  }

  return (
    <button
      className={css.loadMoreButton}
      tabIndex={0}
      onClick={() => {
        logEvent('click', {
          type: 'load_more',
        });
        props.onClick();
      }}
    >
      <div className={css.loadMoreText}>{formatMessage(messages.load_more)}</div>
    </button>
  );
};

LoadMoreButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  isLoading: PropTypes.bool,
};

LoadMoreButton.defaultProps = {
  isLoading: false,
};

LoadMoreButton.displayName = 'LoadMoreButton';

export default injectIntl(StyledComponent(LoadMoreButton, [css]));
