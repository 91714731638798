exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".LoadMoreButton__loadMoreButton--2zgKJ {\n  width: 100%;\n  background-color: #1e1f21;\n  text-align: center;\n  height: 56px;\n  display: flex;\n  align-items: center;\n  justify-content: space-around;\n  cursor: pointer;\n}\n\n.LoadMoreButton__loadMoreButton--2zgKJ:focus {\n  outline: 2px solid #fff;\n}\n\n.LoadMoreButton__loadMoreText--stB8x {\n  opacity: 0.65;\n  font-size: 15px;\n  font-weight: 700;\n  color: #f9f9f9;\n  vertical-align: middle;\n  margin: auto;\n}", ""]);

// exports
exports.locals = {
	"loadMoreButton": "LoadMoreButton__loadMoreButton--2zgKJ",
	"loadMoreText": "LoadMoreButton__loadMoreText--stB8x"
}; 
var style = module.exports.toString();module.exports = exports.locals || { stylesheet: "" };if (!module.exports.stylesheet) { module.exports.stylesheet = style; };