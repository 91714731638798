import React from 'react';
import ScoreTicker from '~/components/ScoreTicker/ScoreTicker';
import { connect } from 'react-redux';

interface Props {
  games: Object[];
  trackEvent?: Function;
  height?: string;
  displayCTA?: string;
  leagueSlug?: string;
}

const unsupportedLeagues = ['lpga', 'pga'];

class ScoreTickerContainer extends React.Component<Props> {
  static displayName = 'ScoreTickerContainer';
  static defaultProps = {
    trackEvent: null,
    height: '100%',
    displayCTA: false,
    leagueSlug: 'nfl',
  };

  isValidLeague() {
    return !unsupportedLeagues.includes(this.props.leagueSlug);
  }

  render() {
    const { games, ...props } = this.props;
    return this.isValidLeague() && !!games.length && <ScoreTicker games={games} {...props} />;
  }
}

const mapStateToProps = state => ({
  games: state.scoreTicker.games,
});

export default connect(mapStateToProps)(ScoreTickerContainer);
