exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".HeadlinesCard__headlinesContainer--E0TMH {\n  width: 100%;\n  height: auto;\n  border-radius: 4px;\n  color: #f9f9f9;\n  background-color: #393c42;\n}\n\n.HeadlinesCard__headlinesHeader--1SzFc {\n  height: 54px;\n  background-color: #1e1f21;\n  border-radius: 4px 4px 0 0;\n}\n\n.HeadlinesCard__headlinesHeaderText--HKXdl {\n  opacity: 0.65;\n  margin: 0;\n  padding: 16px;\n  font-size: 16px;\n  letter-spacing: 2px;\n}\n\n.HeadlinesCard__headlinesBody--11xWF {\n  padding: 16px 0;\n}", ""]);

// exports
exports.locals = {
	"headlinesContainer": "HeadlinesCard__headlinesContainer--E0TMH",
	"headlinesHeader": "HeadlinesCard__headlinesHeader--1SzFc",
	"headlinesHeaderText": "HeadlinesCard__headlinesHeaderText--HKXdl",
	"headlinesBody": "HeadlinesCard__headlinesBody--11xWF"
}; 
var style = module.exports.toString();module.exports = exports.locals || { stylesheet: "" };if (!module.exports.stylesheet) { module.exports.stylesheet = style; };