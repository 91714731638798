exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Headlines__headlines--1_YLt {\n  padding-left: 2em;\n  padding-right: 2em;\n  padding-top: 0;\n  margin-top: 0;\n}\n\n.Headlines__headline--2Sm1L {\n  color: #0078fd;\n  font-size: 13px;\n  font-weight: 400;\n  line-height: 1.83;\n  letter-spacing: 0.3px;\n  margin-bottom: 8px;\n  text-align: left;\n}\n\n.Headlines__headlineText--3ZQm5 {\n  color: #f9f9f9;\n  cursor: pointer;\n}", ""]);

// exports
exports.locals = {
	"headlines": "Headlines__headlines--1_YLt",
	"headline": "Headlines__headline--2Sm1L",
	"headlineText": "Headlines__headlineText--3ZQm5"
}; 
var style = module.exports.toString();module.exports = exports.locals || { stylesheet: "" };if (!module.exports.stylesheet) { module.exports.stylesheet = style; };